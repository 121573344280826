import React, { useState, useEffect } from "react"
import "./style.scss"
import dataLayerEngagement from "../DataLayerEngagement"
import useHasMounted from "../../utils/useHasMounted"
import { StaticImage } from "gatsby-plugin-image"
import { useWindowWidth } from "../../services/hooks"

const Publications = ({ isHomePageV2 }) => {
  const isMounted = useHasMounted()

  const homePageV2Styles = isHomePageV2 ? "one-line-section" : ""

  const handleClick = e => {
    e.preventDefault()
    const aTag = e.target?.parentElement?.parentElement?.parentElement
    dataLayerEngagement(
      "home_page_engagement",
      "4",
      "Featured In",
      `${aTag?.dataset?.link}`
    )
    window.open(aTag?.href, "_blank")
  }

  const Publications = () => {
    const [isMobile, setIsMobile] = useState()
    const windowWidth = useWindowWidth()

    useEffect(() => {
      setIsMobile(windowWidth < 578)
    }, [windowWidth])

    return (
      <>
        <a
          onClick={handleClick}
          data-link="tech-crunch"
          href="https://techcrunch.com/2017/03/07/hubble-raises-16-5-million-to-sell-disposable-contacts-direct-to-consumers/"
          target="_blank"
          aria-label="Read about Hubble on TechCrunch"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image"
              src="./assets/TechCrunch-Mobile.svg"
              alt="TechCrunch Logo"
            />
          ) : (
            <StaticImage
              className="publication-image"
              src="./assets/TechCrunch.svg"
              alt="TechCrunch Logo"
            />
          )}
        </a>
        <a
          onClick={handleClick}
          data-link="mashable"
          href="https://mashable.com/2016/11/01/hubble-contact-lenses-deliver-startup-warby-parker/"
          target="_blank"
          aria-label="Read about Hubble on Mashable"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image"
              src="./assets/Mashable-Mobile.svg"
              alt="Mashable Logo"
            />
          ) : (
            <StaticImage
              className="publication-image"
              src="./assets/Mashable.svg"
              alt="Mashable Logo"
            />
          )}
        </a>
        <a
          onClick={handleClick}
          data-link="vogue"
          href="https://www.vogue.com/article/best-wellness-subscription-services-hubble-tulip-lola-daily-harvest"
          target="_blank"
          aria-label="Read about Hubble on Vogue"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image"
              src="./assets/VOGUE-mobile.svg"
              alt="VOGUE Logo"
            />
          ) : (
            <StaticImage
              className="publication-image"
              src="./assets/VOGUE.svg"
              alt="VOGUE Logo"
            />
          )}
        </a>
        <a
          onClick={handleClick}
          data-link="gq"
          href="https://www.gq.com/story/hubble-contact-lenses"
          target="_blank"
          aria-label="Read about Hubble on GQ"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image"
              src="./assets/GQ-Mobile.svg"
              alt="GQ Logo"
            />
          ) : (
            <StaticImage
              className="publication-image"
              src="./assets/GQ.svg"
              alt="GQ Logo"
            />
          )}
        </a>
      </>
    )
  }

  const PublicationsV2 = () => {
    const [isMobile, setIsMobile] = useState()
    const windowWidth = useWindowWidth()

    useEffect(() => {
      setIsMobile(windowWidth < 578)
    }, [windowWidth])

    return (
      <>
        <a
          onClick={handleClick}
          data-link="vogue"
          href="https://www.vogue.com/article/best-wellness-subscription-services-hubble-tulip-lola-daily-harvest"
          target="_blank"
          aria-label="Read about Hubble on Vogue"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image v2"
              src="./assets/Vogue-v2-mobile.png"
              alt="VOGUE Logo"
              objectFit="contain"
            />
          ) : (
            <StaticImage
              className="publication-image v2"
              src="./assets/Vogue-v2.png"
              alt="VOGUE Logo"
            />
          )}
        </a>
        <a
          onClick={handleClick}
          data-link="gq"
          href="https://www.gq.com/story/hubble-contact-lenses"
          target="_blank"
          aria-label="Read about Hubble on GQ"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image v2"
              src="./assets/GQ-v2-mobile.png"
              alt="GQ Logo"
              objectFit="contain"
            />
          ) : (
            <StaticImage
              className="publication-image v2"
              src="./assets/GQ-v2.png"
              alt="GQ Logo"
            />
          )}
        </a>
        <a
          onClick={handleClick}
          data-link="forbes"
          href="https://www.forbes.com/sites/forbes-personal-shopper/article/best-places-to-buy-contacts-online/?sh=776dd9aae16c"
          target="_blank"
          aria-label="Read about Hubble on Forbes"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image v2"
              src="./assets/Forbes-v2-mobile.png"
              alt="Forbes Logo"
              objectFit="contain"
            />
          ) : (
            <StaticImage
              className="publication-image v2"
              src="./assets/Forbes-v2.png"
              alt="Forbes Logo"
            />
          )}
        </a>
        <a
          onClick={handleClick}
          data-link="mashable"
          href="https://mashable.com/article/hubble-contact-lenses-deliver-startup-warby-parker"
          target="_blank"
          aria-label="Read about Hubble on Mashable"
        >
          {isMobile ? (
            <StaticImage
              className="publication-image v2"
              src="./assets/Mashable-v2-mobile.png"
              alt="Mashable Logo"
              objectFit="contain"
            />
          ) : (
            <StaticImage
              className="publication-image v2"
              src="./assets/Mashable-v2.png"
              alt="Mashable Logo"
            />
          )}
        </a>
      </>
    )
  }

  return (
    <div className={`publications grid-section ${homePageV2Styles}`}>
      {!isHomePageV2 && <p className="publication-title">FEATURED IN</p>}
      <div
        className={`publications-holder desktop ${
          isHomePageV2 ? "one-line-wrapper" : ""
        }`}
      >
        {isHomePageV2 && (
          <span className="publication-title one-line">Featured In</span>
        )}
        {isHomePageV2 ? <PublicationsV2 /> : <Publications />}
      </div>
      <div>
        {isHomePageV2 && (
          <span
            className={`publication-title ${
              isHomePageV2 ? "one-line" : ""
            } mobile`}
          >
            Featured In
          </span>
        )}
        <div
          className={`publications-holder mobile ${isHomePageV2 ? "v2" : ""}`}
        >
          {isHomePageV2 ? <PublicationsV2 /> : <Publications />}
        </div>
      </div>
    </div>
  )
}

export default Publications
