import React from "react"

import "./style.scss"
import ArrowButton from "../ArrowButton"

const SliderPagination = ({
  sliderRef,
  currentPage,
  allPages,
  color,
  noPages,
  isReviews
}) => {
  const handlePrevSlide = () => sliderRef?.current?.slickPrev()

  const handleNextSlide = () => sliderRef?.current?.slickNext()

  return (
    <div className={`slider-pagination ${isReviews && 'reviews'}`} data-color={color}>
      <ArrowButton
        side="left"
        onClick={handlePrevSlide}
        disabled={currentPage <= 1}
        color={color}
      />
      {!noPages && (
        <span className="text bulky pages">{`${currentPage}/${allPages}`}</span>
      )}
      <ArrowButton
        side="right"
        onClick={handleNextSlide}
        disabled={currentPage === allPages}
        color={color}
      />
    </div>
  )
}

export default SliderPagination
