import React, { useRef, useState, useEffect } from "react"
import './style.scss'
import Slider from "react-slick"
import { colorTagsSlider } from "../../services/data"
import SliderPagination from "../SliderPagination"
import SliderPaginationDots from '../SliderPaginationDots'

const settings = {
  speed: 1000,
  draggable: false,
  arrows: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 8000
}

const HubbleSlider = React.forwardRef((props, ref, ) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isPlaying, setIsPlaying] = useState(false)
  const _ref = useRef(ref)
  const currentSlideName = props?.children?.[currentPage - 1]?.props?.product

  const beforeChange = (prev, next) => {
    setCurrentPage(next + 1)
    if (typeof props.beforeChange === 'function') {
      props.beforeChange(prev, next)
    }
  }

  // needed if autoplay is defaulted to false
  // const playSlider = (e) => {
  //   if (!isPlaying) {
  //     _ref?.current?.slickPlay()
  //     setIsPlaying(true)
  //   }
  // }

  // if (typeof window !== "undefined") {
  //   window.addEventListener('pointermove', playSlider, { once: true })
  //   window.addEventListener('touchmove', playSlider, { once: true })
  // }

  return ( <div className="hubble-slider">
    <Slider ref={_ref} {...settings} {...props} beforeChange={beforeChange} >
      {props.children}
    </Slider>

    { (_ref && !props?.isPaginationHidden) ? <SliderPagination
        sliderRef={_ref}
        currentPage={currentPage}
        allPages={props.children?.length}
        color={colorTagsSlider[currentSlideName] || "purple"}
      />
    : null }
    {(_ref && props?.isPaginationHidden) && <SliderPaginationDots 
        sliderRef={_ref}
        currentPage={currentPage}
        allPages={props.children?.length}
        />
      }
</div>)})


export default HubbleSlider
