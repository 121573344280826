import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

import "./style.scss"
import { mainPageSlides, doctorPortalSlides } from "./mockedData"
import SliderPagination from "../SliderPagination"
import { useWindowWidth } from "../../services/hooks"
import useHasMounted from "../../utils/useHasMounted"
import WebpImage from "../WebpImage"

const HowHubbleWorksCarousel = ({ color, isDoctorPortal, title }) => {
  const slides = isDoctorPortal ? doctorPortalSlides : mainPageSlides
  const [currentPage, setCurrentPage] = useState(1)
  const [allPages, setAllPages] = useState(1)
  const [slidesToShow, setSlidesToShow] = useState(1)
  const isMounted = useHasMounted()

  const sliderRef = useRef()

  const settings = {
    adaptiveHeight: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToShow,
    mobileFirst: true,
    draggable: false,
    arrows: false,
    infinite: false,
    beforeChange: (prev, next) => {
      const newCurrentPage = next > prev ? currentPage + 1 : currentPage - 1

      setCurrentPage(newCurrentPage || 1)
    },
  }

  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (windowWidth > 1199) {
      setSlidesToShow(3)
    } else if (windowWidth > 768) {
      setSlidesToShow(2)
    } else {
      setSlidesToShow(1)
    }
  }, [windowWidth])

  useEffect(() => {
    sliderRef.current.slickGoTo(0)
    setCurrentPage(1)
    setAllPages(Math.ceil(slides.length / slidesToShow))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slidesToShow])
  return (
    <div className="works-carousel" data-color={color}>
      <div className="grid-section">
        <h3>{title || "Here's How It Works"}</h3>
        <p className="subtitle">
          Signing up for Hubble's subscription service is quick and easy. Simply
          choose the contact lenses that best suit your needs, enter your
          prescription details, and check out. We'll handle the rest.
        </p>
        <Slider ref={sliderRef} {...settings}>
          {isMounted && slides.map(({ id, image, title, description,alt}) => (
              <div className="works-holder" key={id}>
                <WebpImage fileName={image} alt={alt} />
                <div className="carousel-number">
                  <span>{id}</span>
                </div>
                <p className="carousel-item-title">{title}</p>
                <p className="carousel-copy">{description}</p>
              </div>
            ))}
        </Slider>
        {allPages > 1 && <SliderPagination
            sliderRef={sliderRef}
            currentPage={currentPage}
            allPages={allPages}
            color={color}
          />}
      </div>
    </div>
  )
}

export default HowHubbleWorksCarousel
