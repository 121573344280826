import React, { useState, useEffect, useRef } from "react"
import "./style.scss"
import ProductItem from "../ProductItem"
import { recommendedItems } from "./recommendedItems"
import Slider from "react-slick"
import { useWindowWidth } from "../../services/hooks"
import SliderPagination from "../SliderPagination"

const RecommendedList = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isMobile, setIsMobile] = useState()
  const sliderRef = useRef()
  const allPages = 4
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 1030)
  }, [windowWidth])

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    beforeChange: (prev, next) => {
      const newCurrentPage = next > prev ? currentPage + 1 : currentPage - 1

      setCurrentPage(newCurrentPage || 1)
    },
  }

  return (
    <div className="recommended-wrapper">
        {isMobile ? (
          <div className="recommended-wrapper-slider">
            <Slider
              {...settings}
              className="recommended-container-v3"
              ref={sliderRef}
            >
              {recommendedItems?.map((product, index) => (
                <ProductItem
                  product={product}
                  type={"glasses"}
                  color_scheme="blue"
                  key={index}
                  position={index + 1}
                  isSlider
                />
              ))}
            </Slider>
            <SliderPagination
              sliderRef={sliderRef}
              currentPage={currentPage}
              allPages={allPages}
              color="orange"
            />
          </div>
        ) : (
          <div className="recommended-list">
            {recommendedItems?.map((product, index) => (
              <ProductItem
                product={product}
                type={"glasses"}
                color_scheme="blue"
                key={index}
                position={index + 1}
              />
            ))}
          </div>
        )}
      <div className="recommended-shop-all-wrapper">
        <a className="recommended-shop-all" href='/glasses'>Shop All</a>
      </div>
    </div>
  )
}

export default RecommendedList
