export const reviews = {
  glasses: [
    {
      review_copy: "Hubble contacts are great. Have been wearing them for years. Saw they had glasses. Ordered a pair and they were fabulous and prices are awesome. Will be ordering more!",
      reviewer: "Betsy"
    },
    {
      review_copy: "I tell everyone that I meet who has glasses and contacts about Hubble. We're all looking for a great deal. I love the service and the convenience of shipping. ",
      reviewer: "Aileen"
    },
    {
      review_copy: "Best glasses I've owned.",
      reviewer: "Philip"
    },
    {
      review_copy: "The frames are very stylish. The price is just right.",
      reviewer: "Seriece"
    },
  ],
  contacts: [
    {
      review_copy: "I’m glad I switched to Hubble. I put in a fresh pair every day. Never have to worry about reordering. So crisp and comfortable!!!",
      reviewer: "Zulema J."
    },
    {
      review_copy: "This is my second month using Hubble and I’ll never go back! They are comfortable, affordable, and so healthy for my eyes!",
      reviewer: "Jessi H."
    },
    {
      review_copy: "Omg! When I put them on I was extremely impressed. I'm in love. I cannot even remotely tell they are in. I will be a forever subscriber.",
      reviewer: "Charlie K."
    },
    {
      review_copy: "This is literally the only brand of contacts that works for me. I've tried all of the major brands and was ready to give up. I love my Hubble contacts <3",
      reviewer: "Anna. H"
    },
  ],
  accessory: [
    {
      review_copy: "Thanks to @hubblecontacts for the SUPER SIMPLE process! I will be a return customer for sure!",
      reviewer: "Ashley"
    },
    {
      review_copy: "Because it's so much easier than seeing my eye dr. and way more friendly." ,
      reviewer: "Briana"
    }
  ],
  homepage: [
    {
      review_copy: "Received mine two days ago. Love them. They feel better than the expensive ones from the eye doctor or other mail order contacts. Customer service is wonderful. I highly recommend.",
      reviewer: "Heather P."
    },
    {
      review_copy: "This is literally the only brand of contacts that works for me. I've tried all of the major brands and was ready to give up. I love my Hubble Contacts. <3" ,
      reviewer: "Anna H."
    },
    {
      review_copy: "OMG when I put them on I was extremely impressed. I'm in love, I cannot even remotely tell they are in. I will be a forever subscriber. Thank you Hubble!" ,
      reviewer: "Charlie K."
    },
    {
      review_copy: "Thanks to @hubblecontacts for the SUPER SIMPLE process! I will be a return customer for sure!",
      reviewer: "Ashley"
    }
  ], 
  homepageV2: [
    {
      review_copy: "Received my first 2 boxes of Hubble contacts yesterday & I absolutely LOVE the contacts, so far. Very comfortable & moist all day. I will definitely keep them coming! Thank you Hubble! I will be forever a subscriber.",
      reviewer: "Charlie K."
    },
    {
      review_copy: "This is literally the only brand of contacts that works for me. I've tried all of the major brands and was ready to give up. I love my Hubble Contacts. <3" ,
      reviewer: "Anna H."
    },
    {
      review_copy: "OMG when I put them on I was extremely impressed. I'm in love, I cannot even remotely tell they are in. I will be a forever subscriber. Thank you Hubble!" ,
      reviewer: "Heather P."
    },
    {
      review_copy: "Thanks to @hubblecontacts for the SUPER SIMPLE process! I will be a return customer for sure!",
      reviewer: "Ashley"
    }
  ]
}
