import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/pro-regular-svg-icons"

import "./style.scss"

const ArrowButton = ({ side = "left", onClick, disabled, color }) => {
  return (
    <button
      className="arrow-button"
      onClick={onClick}
      disabled={disabled}
      data-color={color}
      aria-label={`${side} arrow button`}
    >
      <FontAwesomeIcon icon={side === "left" ? faAngleLeft : faAngleRight} />
    </button>
  )
}

export default ArrowButton
