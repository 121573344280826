import React, { useState, useEffect, useRef } from "react"
import PcpItem from "../PcpItem"
import { contactLensesV2 as getContactLensesV2 } from "./mockedData"

import "./style.scss"
import { useWindowWidth } from "../../services/hooks"
import Slider from "react-slick"
import SliderPagination from "../SliderPagination"

const OurContactLenses = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isMobile, setIsMobile] = useState()
  const sliderRef = useRef()
  const windowWidth = useWindowWidth()
  const contactLenses = getContactLensesV2()
 

  useEffect(() => {
    setIsMobile(windowWidth < 1024)
  }, [windowWidth])

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    beforeChange: (prev, next) => {
      const newCurrentPage = next > prev ? currentPage + 1 : currentPage - 1

      setCurrentPage(newCurrentPage || 1)
    },
  }
  
  const isSlider = isMobile

  return (
    <div className={`our-contact-lenses ${isSlider ? 'slider-wrapper' : ''}`}>
      <div className="grid-section">
        <h1>Our Contact {isSlider ? <br></br> : ''}Lenses</h1>
        <div className={`info-comparison ${isSlider ? 'slider' : ''}`}>
          {typeof window === "undefined" ? null : isSlider ? (
            <div className={`press-wrapper`}>
              <Slider
                {...settings}
                className="reviews-container-v3"
                ref={sliderRef}
              >
                {contactLenses.map((item, index) => (
                  <PcpItem {...item} key={index} isSlider={isSlider}/>
                ))}
              </Slider>
              <SliderPagination
                sliderRef={sliderRef}
                currentPage={currentPage}
                allPages={contactLenses.length}
                color={"purple"}
              />
            </div>
          ) : (
            <div className="pcp-items-container">
              {contactLenses.map((item, index) => (
                <PcpItem {...item} key={index} />
              ))}
            </div>
          )}
        </div>
        <div>
          <p className="subtitle">
            With no-fuss subscription personalization, home delivery, and
            hassle-free policies, a Hubble subscription makes ordering contacts
            easy.
          </p>
        </div>
      </div>
    </div>
  )
}

export default OurContactLenses
