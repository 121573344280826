export const mainPageSlides = [
  {
    id: 1,
    image: "Pages/Homepage/HP-How-Hubble-Works-01.webp",
    alt: "A woman wearing glasses reviews her online prescription contact lenses subscription on her laptop.",
    title: "You sign up.",
    description:
      "Choose the Hubble product that best suits your needs. Then just enter your prescription and check out.",
  },
  {
    id: 2,
    image: "Pages/Homepage/HP-How-Hubble-Works-02.jpg",
    title: "We contact your eye doctor.",
    alt: "Eye doctor running a prescription contact lens test",
    description:
      "To dot the i’s and cross the t’s. We’ll let you know via email when everything is all set.",
  },
  {
    id: 3,
    image: "Pages/Homepage/HP-How-Hubble-Works-03.webp",
    title: "We ship your contacts.",
    alt: "Boxes of contact lenses ordered online from Hubble's contact lens subscription, being delivered to a doorstep",
    description:
      "We prep, pack, and ship your contact lenses right to your door. With delivery in about 5 to 10 business days.",
  },
  {
    id: 4,
    image: "Pages/Homepage/HP-How-Hubble-Works-04.webp",
    title: "You take control.",
    alt: "Man wearing glasses ordering prescription contacts online on his smartphone",
    description:
      "Personalize your subscription. Get the number of contacts you need, when you need them.",
  },
  {
    id: 5,
    image: "Pages/Homepage/HP-How-Hubble-Works-05.webp",
    title: "We keep them coming.",
    alt: "Woman unpacking her monthly contact lens prescriptions from Hubble",
    description:
      "Your contacts are now set to arrive like clockwork. (This is where you really see the benefit of your subscription.)",
  },
]

export const doctorPortalSlides = [
  {
    id: 1,
    image: "Pages/DoctorPortal/HP-How-Hubble-Works-01.png",
    alt: "Lenses",
    title: "Fit Your Patients",
    description:
      "Use your Hubble trial lenses to fit patients for their new contact lenses. Your patient will pay and place their order right there in your office.",
  },
  {
    id: 2,
    image: "Pages/DoctorPortal/HP-How-Hubble-Works-02.png",
    alt: "Eye doctor running a prescription contact lens test",
    title: "Place Your Order",
    description:
      "Our easy-to-use portal makes it simple and convenient to place your patient orders. Choose the product, prescription, and quantity, and we will handle the rest.",
  },
  {
    id: 3,
    image: "Pages/DoctorPortal/HP-How-Hubble-Works-03.png",
    alt: "Boxes of contact lenses ordered online from Hubble's contact lens subscription, being delivered to a doorstep",
    title: "Receive Your Shipment",
    description:
      "Lenses can be shipped to your office or to the patient directly. No need to worry about payment hold-ups — we will invoice you monthly and shipping is completely free.",
  }
]
