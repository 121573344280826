import React from "react"
import "./style.scss"
import { StaticImage } from 'gatsby-plugin-image'
import { Link, navigate } from "gatsby"

const HomePageGlassesCtaV2 = () => {

  const handleClick = (e) => {
    e.preventDefault()
    navigate(e.target.href)
  }

  return (
    <div className="homepage-glasses">
      <div className="homepage-glasses-border">
        <div className='image-side'>
          <StaticImage src={"../../../images/Placeholder/Hubble-Eyeglasses-Artemis-Butterscotch-full.png"} alt="" />
        </div>
        <div className='info-side'>
          <div className='info-text'>
            <div className="info-title"><p className="info-title-paragraph">Our Other <br/>Frame of Mind</p></div>
            <p className="info-subtitle">NOW STARTING AT $49.99</p>
            <p className="info-description">When it comes to prescription glasses and sunglasses, we deliver. High-quality, handcrafted frames. On-trend styles. With the option to add blue light filtering to every pair.</p>
          </div>
          <div className='info-buttons'>
            <Link onClick={handleClick} to="/glasses" className="btn btn-primary cta-btn">Shop Eyeglasses</Link>
            <Link onClick={handleClick} to="/sunglasses" className="btn btn-primary cta-btn">Shop Sunglasses</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageGlassesCtaV2
