import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import WebpImage from "../WebpImage"

import './style.scss'
import { useWindowWidth } from '../../services/hooks'

const HomeCta = () => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
      setIsMobile(windowWidth < 767)
  }, [windowWidth])

  return (
    <div className="home-cta v2">
      <div className="home-cta--background">
        <WebpImage wrapperClassName="laptop-image" className="laptop-image" fileName="Pages/Homepage/Hubble-Contacts-400-Million-Lenses-Sold-Desktop.jpg" />
        <WebpImage wrapperClassName="mobile-image" className="mobile-image" fileName="Pages/Homepage/Hubble-Contacts-400-Million-Lenses-Sold-Mobile.jpg" />
      </div>
      <div className="grid-section">
        <h2>Over 400 million lenses sold.</h2>
        <p className="cta-text">You can stop shopping around. {!isMobile && <br/>}We’ll be your single point of contacts.</p>
        <Link to={"/contact-lenses/hubble"} className="btn btn-primary cta-btn">
          Start Your Subscription
        </Link>
      </div>
    </div>
  )}

export default HomeCta
