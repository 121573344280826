import React from "react"

import "./style.scss"

const SliderPaginationDots = ({
  sliderRef,
  currentPage,
  allPages,
  color,
}) => {
  const handleGoToSlide = (idx) => sliderRef?.current?.slickGoTo(idx)

  return (
    <div className={`slider-pagination-dots`} data-color={color}>
      {Array(allPages).fill('').map((_, idx) => 
      <div className={`slider-pagination-dot ${currentPage === idx + 1 ? 'active' : ''}`} onClick={() => handleGoToSlide(idx)}/>
      )}
    </div>
  )
}

export default SliderPaginationDots
