import { Link, navigate } from "gatsby"
import React from "react"
import './style.scss'
import dataLayerEngagement from "../DataLayerEngagement"
import LensesCta from "../LensesCta"
import WebpImage from "../WebpImage"


const HubbleHero = () => {
  const handleClick = (e) => {
    e.preventDefault()
    dataLayerEngagement("home_page_engagement", "1","Hubble Contacts", `${e.target.text}`)
    navigate(e.target.href)
  }

  return (
    <div className="hubble-hero">
      <div className="hubble-hero--background" role="group" aria-roledescription="carousel" aria-label="Hubble Products Carousel">
        <WebpImage objectFit="contain" className="pc-image" fileName="Pages/Homepage/Hubble-Contacts-Homepage-Hero-Desktop.webp" alt="Hubble Main background banner with products"/>
        <WebpImage objectFit="cover" className="ipad-image" fileName="Pages/Homepage/Hubble-Contacts-Homepage-Hero-iPad.webp" alt="Hubble Main background banner with products"/>
        <WebpImage objectFit="fill" wrapperClassName="mobile-image" className="mobile-image" fileName="Pages/Homepage/Hubble-Contacts-Homepage-Hero-Mobile.webp" alt="Hubble Main background banner with products"/>
      </div>
      <div className="grid-section">
        <h1 className="gradient-title">Hubble<br/>Contacts</h1>

        <a href="/contact-lenses" onClick={handleClick} className="btn btn-primary cta-btn">
          Shop Contacts
        </a>
        <a href="/glasses" onClick={handleClick} className="btn btn-primary cta-btn">
          Shop Eyeglasses
        </a>
        <a href="/sunglasses" onClick={handleClick} className="btn btn-primary cta-btn">
          Shop Sunglasses
        </a>
      </div>
    </div>
  )
}

const HubbleLensesCta = ({product, homePageHero}) => {
  return (
    <div className="hubble-hydro-cta">
      <LensesCta product={product} homePageHero={homePageHero} heroBanner/>
    </div>
  )
}

export {HubbleHero as default, HubbleLensesCta}
